require('./bootstrap');

import Litepicker from 'litepicker'
import 'litepicker/dist/plugins/mobilefriendly'
import Iodine from '@kingshott/iodine'
import PhoneNumber from 'awesome-phonenumber'
import Alpine from 'alpinejs'

window.bootstrap = require('bootstrap');
window.PhoneNumber = PhoneNumber
window.Alpine = Alpine

Alpine.start()









